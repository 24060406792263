











import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import djlMultiselect from "@/components/form-components/djlMultiselect.vue";
import FilterSelect from "@/components/form-components/search/FilterSelect.vue";
import { Branch } from "@/models/branch";

@Component({
  components: { FilterSelect, djlMultiselect },
})
@Translatable("form-components.search")
export default class BranchFilter extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private readonly branches!: Branch[];

  @Prop()
  private readonly selectedBranchName: string | undefined;

  @Prop()
  private readonly customLabel!: (branch: Branch) => string;

  private selectedBranch: Branch | null = null;

  mounted() {
    this.setBranch();
  }

  @Watch("selectedBranchName")
  @Watch("branches")
  private setBranch() {
    this.selectedBranch = this.selectedBranchName
      ? this.branches.find(
          (branch) => branch.name === this.selectedBranchName
        ) ?? null
      : null;
  }

  private selectBranch(newBranch: Branch | null): void {
    this.$emit("branch-changed", newBranch);
  }
}
