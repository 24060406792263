


































































import DocumentsOverview from "@/components/documents/DocumentsOverview.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { ITasks, Link, PaginatedResponse } from "@/models/document";
import { DocumentParameters } from "@/api/portal/tasks";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import { TaskCategory, taskFilter } from "@/models/task";
import { Role } from "@/models/role";
import { namespace } from "vuex-class";
import VueI18n from "vue-i18n";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";
import { taskReadApi } from "@/api/portal/task-read-api";
import SortableTable from "@/components/tables/SortableTable.vue";
import AccountFilter from "@/components/form-components/search/AccountFilter.vue";
import { accountLabel } from "@/utils/accountDisplayFormatter";
import { Account } from "@/interfaces/Account";
import BranchFilter from "@/components/form-components/search/BranchFilter.vue";
import { Branch, BusinessUnit } from "@/models/branch";

const user = namespace("user");
@Component({
  components: {
    BranchFilter,
    AccountFilter,
    SortableTable,
    TheHeader,
    DocumentsOverview,
    Page,
    Loader,
  },
})
@Translatable("documents")
export default class Documents extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private category!: TaskCategory;

  @Prop()
  private readonly selectedAccountId: string | undefined;

  @Prop()
  private readonly selectedBranchName: string | undefined;

  @user.State("role")
  private role!: Role;

  @user.State("userId")
  private userId!: string;

  private loading: boolean = false;

  private accounts: Account[] = [];
  private branches: Branch[] = [];

  private paginatedResponse: PaginatedResponse<ITasks> | null = null;

  private sort: string = "endDate,asc";

  get hasDocuments(): boolean {
    return (this.paginatedResponse?._embedded?.tasks ?? []).length > 0;
  }

  get hasBranchFilter(): boolean {
    return this.role === "employee";
  }

  get customLabel(): (account: Account) => string {
    return this.role === "employee" ? accountLabel : (account) => account.name;
  }

  private onAccountChanged(account: Account | null) {
    const newAccountId = account?.id ?? undefined;
    if (newAccountId === this.selectedAccountId) {
      return;
    }

    const currentQuery = { ...this.$route?.query };

    if (newAccountId) {
      currentQuery.selectedAccountId = newAccountId;
    } else {
      delete currentQuery.selectedAccountId;
    }

    this.$router.push({ query: currentQuery });
  }

  private onBranchChanged(branch: Branch | null) {
    const newBranchName = branch?.name ?? null;
    if (newBranchName === this.selectedBranchName) return;

    const currentQuery = { ...this.$route?.query };

    if (newBranchName) {
      currentQuery.selectedBranchName = newBranchName;
    } else {
      delete currentQuery.selectedBranchName;
    }

    this.$router.push({ query: currentQuery });
  }

  get pageSettings(): {
    header: {
      headerImage: string;
      title: VueI18n.TranslateResult;
      subtitle: VueI18n.TranslateResult;
    };
  } {
    if (this.role === "customer") {
      return {
        header: {
          headerImage: "merkbeeld2.jpg",
          title: this.translated_view_value("my_tasks.header.title"),
          subtitle: this.translated_view_value("my_tasks.header.subtitle"),
        },
      };
    }
    const translations: Record<
      TaskCategory,
      {
        header: {
          headerImage: string;
          title: VueI18n.TranslateResult;
          subtitle: VueI18n.TranslateResult;
        };
      }
    > = {
      my: {
        header: {
          headerImage: "merkbeeld2.jpg",
          title: this.translated_view_value("my_tasks.header.title"),
          subtitle: this.translated_view_value("my_tasks.header.subtitle"),
        },
      },
      team: {
        header: {
          headerImage: "teamtasks.jpg",
          title: this.translated_view_value("my_team_tasks.header.title"),
          subtitle: this.translated_view_value("my_team_tasks.header.subtitle"),
        },
      },
      active: {
        header: {
          headerImage: "activetasks.jpg",
          title: this.translated_view_value("my_active_tasks.header.title"),
          subtitle: this.translated_view_value(
            "my_active_tasks.header.subtitle"
          ),
        },
      },
      customer: {
        header: {
          headerImage: "customertasks.jpg",
          title: this.translated_view_value("my_customer_tasks.header.title"),
          subtitle: this.translated_view_value(
            "my_customer_tasks.header.subtitle"
          ),
        },
      },
    };
    return translations[this.category];
  }

  get taskCategory(): TaskCategory {
    if (this.role === "customer") {
      return "my";
    }
    return this.category;
  }

  get documentsParams(): DocumentParameters {
    const { scope, documentStatus } = taskFilter(this.category, this.role);
    return {
      scope: scope,
      status: documentStatus,
      accountId: this.selectedAccountId,
      businessUnitId:
        this.branches.find(
          (branch: Branch) => branch.name === this.selectedBranchName
        )?.businessUnitIds ?? undefined,
      sort: this.sort,
      pageSize: 20,
    };
  }

  mounted() {
    this.loading = true;
    this.getAccounts();
    if (this.hasBranchFilter) this.getBranches();
    this.getDocuments(this.documentsParams);
  }

  private getAccounts() {
    taskReadApi
      .accounts()
      .then((response) => {
        this.accounts = response.data;
      })
      .catch(() => {
        this.paginatedResponse = null;
        this.$snotify.error(
          this.translated_view_value("loading.error_accounts")
        );
      });
  }

  private getBranches() {
    taskReadApi
      .businessUnits()
      .then((response) => {
        const branchMap: Record<string, Branch> = {};
        response.data.forEach((businessUnit: BusinessUnit) => {
          if (!branchMap[businessUnit.name]) {
            branchMap[businessUnit.name] = {
              name: businessUnit.name,
              businessUnitIds: [],
            };
          }
          branchMap[businessUnit.name].businessUnitIds.push(businessUnit.id);
        });

        this.branches = Object.values(branchMap);
      })
      .catch(() => {
        this.paginatedResponse = null;
        this.$snotify.error(
          this.translated_view_value("loading.error_branches")
        );
      });
  }

  @Watch("documentsParams")
  private getDocuments(params?: DocumentParameters) {
    taskReadApi
      .tasks(params)
      .then((response) => {
        this.paginatedResponse = response.data;
      })
      .catch(() => {
        this.paginatedResponse = null;
        this.$snotify.error(this.translated_view_value("loading.error"));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  paginationClickHandler(link: Link): void {
    taskReadApi
      .fromLink(link)
      .then((response) => {
        this.paginatedResponse = response.data;
      })
      .catch(() => {
        this.paginatedResponse = null;
        this.$snotify.error(this.translated_view_value("loading.error"));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  sortChangeHandler(newSort: string): void {
    this.sort = newSort;
  }
}
