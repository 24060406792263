
























































import { Component, Prop, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  Document,
  DocumentScope,
  IDocument,
  ITasks,
  Link,
  PaginatedResponse,
} from "@/models/document";
import djlMultiSelect from "@/components/form-components/djlMultiselect.vue";
import { Breakpoints } from "@/store/modules/layout";
import { formatIsoDate } from "@/filters";

import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import SortableTable, {
  TableHeaders,
} from "@/components/tables/SortableTable.vue";
import { TaskCategory } from "@/models/task";
import { Role } from "@/models/role";

const layout = namespace("layout");
const user = namespace("user");

class SortableTask extends Document {
  type: string;
  accountId: string;
  organizationalUnitId: string;
  employeeSignerName: string;

  constructor(document?: Partial<Document>) {
    super();
    Object.assign(this, document);
    this.type = document?.documentType ?? "";
    this.accountId = document?.account?.id ?? "";
    this.organizationalUnitId = document?.organization?.id ?? "";
    this.employeeSignerName = document?.employeeSigner
      ? `${document?.employeeSigner?.firstName} ${document?.employeeSigner?.lastName}`
      : "";
  }

  static fromDocument(document: IDocument): Document {
    return new SortableTask(document);
  }
}

interface OrderedTableHeaders extends TableHeaders<SortableTask> {
  order: number;
  class?: string | null;
}

@Component({
  components: { SortableTable, djlMultiSelect },
  methods: { formatIsoDate },
})
@Translatable("documents")
export default class DocumentsOverview extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Ref() readonly accountMultiselect!: djlMultiSelect;

  Document = Document;

  @Prop()
  private paginatedResponse!: PaginatedResponse<ITasks>;

  @Prop()
  private taskCategory!: TaskCategory;

  @Prop({ required: false, default: undefined })
  private selectedAccountId: string | undefined;

  @layout.Getter
  public responsiveAtLeast!: (breakpoint: Breakpoints) => boolean;

  @layout.Getter
  public responsiveUpTo!: (breakpoint: Breakpoints) => boolean;

  @user.State("role")
  private role!: Role;

  @Prop()
  private scope?: DocumentScope;

  get sortableDocuments() {
    return (this.paginatedResponse._embedded?.tasks ?? []).map((e) =>
      SortableTask.fromDocument(e)
    );
  }

  private getTableHeaders() {
    const headers: OrderedTableHeaders[] = [
      {
        property: "type",
        name: this.$t("models.document.documentType"),
        sortable: true,
        order: 1,
      },
      {
        property: "period",
        name: this.$t("models.document.period"),
        sortable: true,
        order: 2,
        class: "hidden-tablet-down",
      },
      {
        property: "accountId",
        name: this.$t("models.document.account"),
        sortable: false,
        order: 3,
      },
      {
        property: "organizationalUnitId",
        name: this.$t("models.document.organization"),
        sortable: false,
        order: 4,
        class: "hidden-tablet-down",
      },
      {
        property: "endDate",
        name: this.$t("models.document.endDate"),
        sortable: true,
        order: 5,
      },
      {
        property: "state",
        name: this.$t("models.document.state"),
        sortable: true,
        order: 6,
      },
    ];

    if (this.scope === DocumentScope.ALL) {
      headers.push({
        property: "employeeSignerName",
        name: this.$t("models.document.signer_employee"),
        sortable: true,
        order: 8,
      });
    }

    return headers.sort((a, b) => a.order - b.order);
  }

  private onDocumentClicked(document: Document) {
    this.$router.push({
      name: "documents-show",
      params: { documentId: document.id },
      query: {
        category: this.taskCategory,
        selectedAccountId: this.$route.query?.selectedAccountId ?? undefined,
        selectedBranchName: this.$route.query?.selectedBranchName ?? undefined,
      },
    });
  }

  private accountNameFromDocument(document: Document) {
    if (!this.documentHasEntity(document)) {
      return "Geen entiteit gekoppeld";
    }
    return document.account.name;
  }

  private documentHasEntity(document: Document) {
    return !!document.account;
  }

  paginationClickHandler(link: Link): void {
    this.$emit("pagination-click", link);
  }

  sortChangeHandler(sort: string): void {
    this.$emit("sort-change", sort);
  }
}
