







































































import { Component, Prop } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import TranslatableComponent from "@/lib/mixins/translateable";
import { NestedKeyOf } from "@/components/tables/types";
import ScrollIndicator from "@/components/tables/ScrollIndicator.vue";
import Pagination from "@/components/Pagination.vue";
import TablePagination from "@/components/tables/TablePagination.vue";
import { ITasks, Link, PaginatedResponse } from "@/models/document";

export type SortDirection = "asc" | "desc";

export interface TableHeaders<T extends object> {
  property: NestedKeyOf<T>;
  name: string | TranslateResult;
  sortable: boolean;
}

@Component({
  components: { TablePagination, Pagination, ScrollIndicator },
})
export default class SortableTable<
  T extends object
> extends TranslatableComponent {
  @Prop()
  readonly data!: T[];

  @Prop()
  private paginatedResponse!: PaginatedResponse<ITasks>;

  @Prop()
  private headers!: TableHeaders<T>[];

  @Prop()
  private index!: keyof T;

  private sortBy: string = "endDate";
  private sortDir: SortDirection = "asc";

  get isPaged(): boolean {
    return (this.paginatedResponse._links?.page ?? []).length > 1;
  }

  changeSorting(column: TableHeaders<T>) {
    if (this.sortBy !== column.property) {
      this.sortBy = column.property;
      this.sortDir = "asc";
    } else {
      this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
    }

    this.$emit("sort-change", `${this.sortBy},${this.sortDir}`);
  }

  paginationClickHandler(link: Link): void {
    this.$emit("pagination-click", link);
  }
}
