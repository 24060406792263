



























import { Component, Prop } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { ITasks, Link, PaginatedResponse } from "@/models/document";

@Component
export default class TablePagination extends TranslatableComponent {
  @Prop()
  readonly paginatedResponse!: PaginatedResponse<ITasks>;

  paginationClickHandler(link: Link): void {
    this.$emit("pagination-click", link);
  }
}
